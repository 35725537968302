
import { computed, defineComponent, ref } from "vue"

export default defineComponent({
    name: "Pagination",
    props: {
        arr: Array,
        totalBanco: Number,
        tipo: String,
    },
    emits: ['pageChanged'],
    setup(props, {emit}) {

        function usePagination() {
            const state = ref({
                currentPage: 1,
                itemsPerPage: 10,
                itemsOnPage: 0,
            });

            const pageCount = computed(() => {
                if (props.totalBanco === undefined) return 0; 
                return Math.ceil(props.totalBanco / state.value.itemsPerPage);
            });

            const itemsOnPage = computed(() => {
                const startIndex = (state.value.currentPage - 1) * state.value.itemsPerPage;
                const endIndex = startIndex + state.value.itemsPerPage;
                return (props.arr ? props.arr : []).slice(startIndex, endIndex);
            });

            const setCurrentPage = page => {
                state.value.currentPage = page;
                state.value.itemsOnPage = itemsOnPage.value.length;
                emit('pageChanged', page);
            };

            return {
                state,
                setCurrentPage,
                pageCount,
                itemsOnPage,
            };
        }
        
        const { state, setCurrentPage, pageCount, itemsOnPage } = usePagination();
        function resetPage(page) {
          state.value.currentPage = page
          console.log(state.value.currentPage)
        }

        return { state, setCurrentPage, pageCount, itemsOnPage, resetPage }
    }
})
