
import {
  defineComponent,
  Ref,
  ref,
  onMounted,
  watch,
  onBeforeMount,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Title from "@/components/Title.vue"
import Button from "@/components/Button.vue"
import { useRouter, useRoute } from "vue-router";
import Api from "@/services/Api";
import moment from "moment";
import { useTicketStore } from "@/store/TicketStore";
import { useLoaderStore } from "@/store/LoaderStore";
import Pagination from "@/components/Pagination.vue";
import { ProjectModel } from "@/models/ProjectModel";
import { UsuarioModel } from "@/models/UsuarioModel";
import { useSocketStore } from '@/store/SocketPinia';
import { useOpenAudioNotification } from "@/store/OpenAudioNotification";

interface Input {
  cargo: string,
  data: string
}


export default defineComponent({
  name: "Tickets",

  components: {
    Title,
    Button,
    Pagination
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const storeFilter = useTicketStore();
    const storeLoader = useLoaderStore();
    const itemsPerPage: Ref<number> = ref(10)
    const page: Ref<number> = ref(1)
    const count: Ref<number> = ref(1)
    const totalItems: Ref<number> = ref(0)
    const categoryParamPath: Ref<number | string | null> = ref(null)
    const excludeFechados = ref(true)
    const arrayTickets: Ref<any> = ref([]);
    const user = ref<UsuarioModel | null>(null)
    const projecList = ref<ProjectModel[] | null>(null)
    const eventName: Ref<string|null> = ref(null)
    const socketStore = useSocketStore();
    const AudioNotification = useOpenAudioNotification();
    const idProject: Ref<number | null> = ref(null)
    const ticketsPaginationRef = ref<InstanceType<typeof Pagination> | null>(null);

    async function getTickets() {
      const { title, date, solicitante, status, support, store, category, codigo, cnpj } = storeFilter.getFilter

      if(status === 'Fechado'){
        excludeFechados.value = false
      }

      const report_date = formatDate(date[0])
      const end_date =  formatDate(date[1])

      storeLoader.open()
      try {
        const { data } = await Api.get("tickets", { 
          store,
          status,
          cnpj,
          title,
          report_date,
          end_date,
          solicitante,
          support,
          category: categoryParamPath.value ?? category,
          perPage: itemsPerPage.value,
          page: page.value,
          id_ticket: codigo ,
          fechado: excludeFechados.value
        })
        arrayTickets.value = data.tickets
        totalItems.value = data.total_tickets
        
      } catch (error) {
        console.log("error", error)
      }finally{
        storeLoader.close()
      }
    }

    function formatDate(date){
      if(date){
        const formattedDate = moment(date).format('YYYY/MM/DD')
        return formattedDate
      }
      return ''
    }

    function statusColor(status: string) {
      let color: string;
      switch (status) {
        case "Aberto":
          color = "#F64E60"
          break;
        case "Andamento":
          color = "#3699FF"
          break;
        case "Desenvolvimento":
          color = "#3699FF"
        break;
        case "Retorno Cliente":
          color = "#3699FF"
        break;
        case "Aguardando tablet":
          color = "#3699FF"
          break;
        case "Aguardando produtos":
          color = "#3699FF"
        break;
        case "Aguardando treinamento":
          color = "#3699FF"
        break;
        case "Fechado":
          color = "#1BC5BD"
          break;
        default:
          color = "#000000";
        }
        return color;
    }


    watch(
      ()=> storeFilter.filter,
      ()=> {
        page.value = 1
        getTickets()
        if (ticketsPaginationRef.value) {
            ticketsPaginationRef.value.resetPage(1);
          } 
      },
      {deep: true}
    )

    watch(
      () => itemsPerPage.value,
      () => {
        getTickets()
      }
    )

    // watch(() => route.path, (newPath, oldPath) => {
    //   if(newPath === '/listaTicketsImplantacoes') {
    //     categoryParamPath.value = 'Implantações';
    //     handlePageChange(1);
    //     return;
    //   } else {
    //     categoryParamPath.value = null;
    //     handlePageChange(1);
    //     return;
    //   }
    // });

    function handlePageChange(pageParam) {
      page.value = pageParam;
      getTickets();
    }

    function getChats(num: number){
      // categoryParamPath.value = num % 2 === 0 ? null : 'Aplicativo'
      getTickets();
    }

    function getDatasFromLocalStorage() {
      try {
        let projecListLS: ProjectModel[] | string | null = localStorage.getItem("projects")
        projecListLS = typeof projecListLS === "string" ? JSON.parse(projecListLS) : null
        if(Array.isArray(projecListLS) && projecListLS.length)
          projecList.value = [ ...projecListLS ]

        let userLS: UsuarioModel | string | null = localStorage.getItem("user")
        userLS = typeof userLS === "string" ? JSON.parse(userLS) : null
        if(typeof userLS === "object" && userLS?.nivelCargo)
          user.value = userLS
      } catch (error) {
        console.error(error)
      }
    }

    function ativaEventoSocket(){
      socketStore.startSocket();
      listeningEvent();
    }

    function verificarStatus(ticketJason) {
      if(ticketJason === null) {
        return "Aberto";
      }
      const campos = JSON.parse(ticketJason).campos;
      if (!campos) {
        return "Aberto";
      }
      const solicitacaoTablet = campos.find(campo => campo.label === "Solicitação do tablet")?.value;
      const chegadaTablet = campos.find(campo => campo.label === "Chegada do tablet")?.value;
      const solicitacaoProdutos = campos.find(campo => campo.label === "Solicitação da base de produtos")?.value;
      const recebimentoProdutos = campos.find(campo => campo.label === "Recebimento da base de produtos")?.value;
      const dataTreinamento = campos.find(campo => campo.label === "Data do treinamento")?.value;

      const dataAtual = new Date();

      // Verifica se data de treinamento está preenchida e se é maior que a data atual (status "aguardando treinamento")
      if (dataTreinamento) {
        const dataTreinamentoDate = new Date(dataTreinamento);

        if (dataTreinamentoDate > dataAtual) {
          return "Aguardando treinamento";
        }

        // Se a data de treinamento é menor ou igual à atual (status "fechado")
        return "Fechado";
      }
      // Verifica se solicitação de produtos está preenchida e recebimento de produtos não (status "aguardando produtos")
      if (solicitacaoProdutos && !recebimentoProdutos) {
        return "Aguardando produtos";
      }
      // Verifica se solicitação de tablet está preenchida e chegada do tablet não (status "aguardando tablet")
      if (solicitacaoTablet && !chegadaTablet) {
        return "Aguardando tablet";
      }

      // Verifica se todos os campos estão nulos (status "aberto")
      if (!solicitacaoTablet && !chegadaTablet && !solicitacaoProdutos && !recebimentoProdutos && !dataTreinamento) {
        return "Aberto";
      }

      // Se nenhum status específico foi encontrado, retorna "Aberto" como fallback
      return "Aberto";
    }

    function listeningEvent(){
      eventName.value = `${process.env.VUE_APP_ENV}-createdTicket-${localStorage.getItem('id_project') || '1'}`
      socketStore.newListen({
        name: eventName.value,
        callbackFunction: (data) => {
          getTickets();
        },
      });
    }

    watch(() => AudioNotification.status, (newStatus) => {
      ativaEventoSocket();
    });

    onBeforeMount(() => {
      getDatasFromLocalStorage()
    })

    onMounted(async () => {
      // categoryParamPath.value = route.path === '/listaTicketsImplantacoes' ? 'Implantações' : null;
      idProject.value = JSON.parse(localStorage.getItem('id_project') as any)
      // console.log('project', project.value)
      getTickets();
      ativaEventoSocket();

      // const observer = new IntersectionObserver(entries => {
      //   if(entries[0].isIntersecting) {
      //     itemsPerPage.value = itemsPerPage.value + 10
      //   }
      // }).observe(document.querySelector('#load') as any)
    })

    return {
      history,
      router,
      arrayTickets,
      moment,
      statusColor,
      itemsPerPage,
      totalItems,
      handlePageChange,
      getChats,
      count,
      route,
      projecList,
      user,
      verificarStatus,
      idProject,
      ticketsPaginationRef,
      storeLoader
    };
  },
});
