import { defineStore } from "pinia";
import moment from "moment";

export const useTicketStore = defineStore('TicketStore', {
  // state
  state: () => {
    return {
      filter: {
        title: "",
        codigo: "",
        status: "",
        store: "",
        category: "",
        solicitante: "",
        support: "",
        cnpj: "",
        date: "",
      },
      ticket: {
        id: "",
        cnpj: "",
        store_name: "",
        store_manager: "",
        solicitante: "",
        store_contact: "",
        erp: false,
        possui_conecta: null,
        title: '',
        id_status: null,
        id_category: null,
        report_date: '',
        periodo: '',
        support: '',
        description: '',
        resolution: '',
      }
    }
  },
  getters: {
    getFilter(state){
      return state.filter
    },
    getTicket(state){
      return state.ticket
    }
  },
  actions: {
    sendFilter(inputFields){
      this.filter.title = inputFields.title
      this.filter.cnpj = inputFields.cnpj
      this.filter.status = inputFields.status
      this.filter.store = inputFields.store
      this.filter.solicitante = inputFields.solicitante
      this.filter.support = inputFields.support
      this.filter.date = inputFields.date
      this.filter.category = inputFields.category
      this.filter.codigo = inputFields.codigo
    },
    sendTicket(inputFields){
      this.ticket.title = inputFields.title
      this.ticket.id = inputFields.id
      this.ticket.id_status = inputFields.status
      this.ticket.id_category = inputFields.category
      this.ticket.store_name = inputFields.store_name
      this.ticket.solicitante = inputFields.solicitante
      this.ticket.support = inputFields.support
      this.ticket.report_date = moment(inputFields.report_date).format("YYYY/MM/DD")
      this.ticket.store_manager = inputFields.store_manager
      this.ticket.store_contact = inputFields.store_contact
      this.ticket.cnpj = inputFields.cnpj
      this.ticket.periodo = inputFields.periodo
      this.ticket.description = inputFields.description
      this.ticket.resolution = inputFields.resolution
      this.ticket.erp = inputFields.erp
      this.ticket.possui_conecta = inputFields.possui_conecta

    },
    clearFilter(){
      this.filter.title = ''
      this.filter.cnpj = ''
      this.filter.codigo = ''
      this.filter.status = ''
      this.filter.store = ''
      this.filter.solicitante = ''
      this.filter.support = ''
      this.filter.date = ''
    }
  }
})